import { writable, derived } from "svelte/store";
import { generateDate } from "../logic";
import * as storage from "../storage";
// initial state
export const currentDate = writable(generateDate(1800, 2100));
export const currentDay = derived(currentDate, ($currentDate) => $currentDate.getDay());
export const isTimed = writable(storage.getObject("isTimed") || false);
export const results = writable(storage.getObject("results") || []);
export const dayClicked = writable(null);
export const isWin = writable(null);
export const startTime = writable(performance.now());
export const timeTaken = writable(null);
