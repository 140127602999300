<script lang="ts">
  let firstGame: boolean = true;
  import {
    currentDate,
    isTimed,
    isWin,
    timeTaken,
    results,
  } from "./store/index";
  import { handleReplay } from "./store/actions";
  import Parameters from "./components/Parameters.svelte";
  import Answers from "./components/Answers.svelte";
  import Status from "./components/Status.svelte";
  import Statistics from "./components/Statistics.svelte";

  function startGame() {
    firstGame = false;
    handleReplay();
  }
</script>

<main>
  <h1>Quiztidien</h1>
  {#if firstGame}
  <button id="replay-button" on:click="{startGame}">Jouer</button>
  {:else}
  <div id="date-display">{$currentDate.toLocaleDateString('fr-FR')}</div>
  <Answers />
  <Status />
  {/if}
  <Parameters />
  {#if ($results.length !== 0)}
  <Statistics />
  {/if}
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 400;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
  #date-display {
    margin: 10px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 32px;
  }
</style>
