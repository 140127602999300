<script lang="ts">
  import { currentDay, isTimed, isWin, timeTaken } from "../store/index";
  import { handleReplay } from "../store/actions";

  const numberToDay = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const timeDisplay = (timeTaken: number) => {
    const timeTakenMillis = Number(timeTaken % 100).toPrecision(2);
    const timeTakenSeconds = Math.floor((timeTaken / 1000) % 60);
    const timeTakenMinutes = Math.floor(timeTaken / 60000);
    return `${
      timeTakenMinutes > 0 ? timeTakenMinutes + "min" : ""
    } ${timeTakenSeconds} sec ${timeTakenMillis}`;
  };

  $: currentDayString = numberToDay[$currentDay];
</script>

{#if $isWin === true}
<div>
  <div>Correct ! 🥳</div>
  {#if $isTimed}
  <div class="time-display" style="font-weight: bold">
    {timeDisplay($timeTaken)}
  </div>
  {/if}
  <div class="replay-button-container">
    <button id="replay-button" on:click="{handleReplay}">Rejouer</button>
  </div>
</div>

{:else if $isWin === false}
<div>
  <div>Faux. C'est un {currentDayString} ({$currentDay}).</div>
  {#if $isTimed}
  <div class="time-display" style="font-weight: bold">
    {timeDisplay($timeTaken)}
  </div>
  {/if}
  <div class="replay-button-container">
    <button id="replay-button" on:click="{handleReplay}">Rejouer</button>
  </div>
</div>
{/if}
