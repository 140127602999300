<script lang="ts">
  import { Line } from "svelte-chartjs";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
  } from "chart.js";
  import { Result, results } from "../store/index";
  $: played = $results.length;
  $: won = $results.filter((s) => s.status).length;
  $: datasets = {
    labels: $results
      .filter((v: Result) => v.status && v.time !== null)
      .map((v: Result) => new Date(v.timestamp).toLocaleDateString()),
    datasets: [
      {
        label: "Time",
        tension: 0.2,
        data: $results
          .filter((v: Result) => v.status && v.time !== null)
          .map((v: Result) => v.time / 1000),
        borderWidth: 2,
        borderColor: "rgb(35, 26, 136)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true
  };
  const width = 300;
  const height = 300;

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale
  );
</script>

<div>
  <p>Parties gagnées : {won} / {played}</p>
  <div class="chart-container">
    <Line class="chart-canvas" data="{datasets}" {width} {height} {options} />
  </div>
</div>

<style>
  .chart-container {
    max-height: 400px;
    max-width: 700px;
    display: block;
    margin: auto;
  }
</style>
