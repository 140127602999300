<script lang="ts">
  import { isWin } from "../store/index.js";
  import { handleClickDay } from "../store/actions";

  const numberToDay = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  function clickDay(dayNumber: number) {
    return () => {
      if ($isWin === null) handleClickDay(dayNumber);
    };
  }
</script>

<div class="answers-div">
  {#each Array(7) as _, i}
  <button class="answer-button" on:click="{clickDay((i + 1) % 7)}">
    {numberToDay[(i + 1) % 7]}
  </button>
  {/each}
</div>

<style>
  .answer-button {
    width: 40%;
    margin: 2px;
  }
  @media (min-width: 640px) {
    .answer-button {
      width: 20%;
    }
  }
  @media (min-width: 1000px) {
    .answer-button {
      width: 10%;
    }
  }
</style>
