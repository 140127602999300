export function generateDate(lowerYear, upperYear) {
    const lowerTimestamp = new Date(lowerYear, 0, 1).valueOf();
    const upperTimestamp = new Date(upperYear, 11, 31).valueOf();
    const timeDiff = upperTimestamp - lowerTimestamp;
    const chosenTimestamp = Math.random() * timeDiff + lowerTimestamp;
    return new Date(chosenTimestamp);
}
export function verifyAnswer(currentDate, answer) {
    const correctAnswer = currentDate.getDay();
    return correctAnswer === answer % 7;
}
