<script>
  import { isTimed } from "../store/index";
  import { handleToggleTime } from "../store/actions";
  const callback = () => {
    handleToggleTime();
  };
</script>

<div>
  <label>
    <input type="checkbox" checked="{$isTimed}" on:change="{callback}" />
    Chronométré
  </label>
</div>
