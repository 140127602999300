import { currentDate, dayClicked, isWin, isTimed, timeTaken, startTime, results, } from "./index";
import { verifyAnswer, generateDate } from "../logic";
import * as storage from "../storage";
let _currentDate;
let _results;
let _timeTaken;
let _startTime;
let _isTimed;
let _isWin;
currentDate.subscribe((v) => (_currentDate = v));
results.subscribe((v) => (_results = [...v]));
timeTaken.subscribe((v) => (_timeTaken = v));
startTime.subscribe((v) => (_startTime = v));
isTimed.subscribe((v) => (_isTimed = v));
isWin.subscribe((v) => (_isWin = v));
export function handleClickDay(dayNumber) {
    dayClicked.set(dayNumber);
    isWin.set(verifyAnswer(_currentDate, dayNumber));
    if (_isTimed) {
        const endTime = performance.now();
        timeTaken.set(endTime - _startTime);
    }
    results.update((v) => [
        ...v,
        {
            status: _isWin,
            time: _isTimed ? _timeTaken : null,
            timestamp: Date.now(),
        },
    ]);
    storage.setObject("results", _results);
    console.log(_results);
}
export function handleReplay() {
    dayClicked.set(null);
    isWin.set(null);
    currentDate.set(generateDate(1800, 2100));
    startTime.set(performance.now());
}
export function handleToggleTime() {
    isTimed.update((v) => !v);
    storage.setObject("isTimed", _isTimed);
}
